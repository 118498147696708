import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'

const ResidualsPage = () => {
  return (
    <Layout pageTitle="Residual Services">
        <Container fluid="xxl">
          <Row>
            <Col className="no-gutters">
              <div className="hero-banner large-hero">
                <StaticImage
                  className="hero-image"
                  src="../../../media/lagoon-cleaning-hero.jpg"
                  alt=""
                  aspectRatio={3.75 / 1}
                  layout="fullWidth"
                />
                <div className="hero-fade"></div>
                <div className="hero-text">
                  <h2>Services</h2>
                  <h1>Residuals</h1>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <main>
          <Container fluid="xxl" className="section text-center">
            <Row md={3} xs={1}>
              <Col>
                <Link to="nasm">
                  <StaticImage
                    src="../../../media/NASM1.png"
                    alt=""
                    height="175"
                  />
                </Link>
                <p>
                  Wessuc takes the headache out of the process for you. <Link to="nasm">Click here</Link> for a list of approved materials and Why Wessuc is the choice for so many waste management solutions.
                </p>
              </Col>
              <Col>
                <Link to="land-application">
                  <StaticImage
                    src="../../../media/HAULAGE2-3.png"
                    alt=""
                    height="175"
                  />
                </Link>
                <p>
                  The Wessuc NASM team works with local farmers and their schedules for applying approved materials to land. Our field operators are <Link to="http://www.omafra.gov.on.ca/english/nm/nasm/farmers.htm">OMAFRA</Link> trained and certified.
                </p>
              </Col>
              <Col>
                <Link to="haulage">
                  <StaticImage
                    src="../../../media/HAULAGE2.png"
                    alt=""
                    height="175"
                  />
                </Link>
                <p>
                  Wessuc is your one-stop-shop-for-slop. That's why so many of our customers use us for the complete process from approvals to movement of materials. Let us take a load off with our fleet of tanks.
                </p>
              </Col>
            </Row>
          </Container>
          <Container fluid="xxl"><hr/></Container>
          <Container fluid="xxl" className="section text-center">
            <Row md={3} xs={1}>
              <Col>
                <Link to="soil-testing">
                  <StaticImage
                    src="../../../media/HAULAGE2-1.png"
                    alt=""
                    height="175"
                  />
                </Link>
                <p>
                  Correct nutrient management programs and fertilizer blends for the best rate of return are an integral part of the process. That's why Wessuc provides many soil sampling techniques.
                </p>
              </Col>
              <Col>
                <Link to="sludge-judge">
                  <StaticImage
                    src="../../../media/HAULAGE2-2.png"
                    alt=""
                    height="175"
                  />
                </Link>
                <p>
                  What is a sludge judge? It's a tool used to sample the materials in a lagoon and also the depth of the sludge - determining when its time for a clean out. <Link to="sludge-judge">Click here</Link> to see how it's done.
                </p>
              </Col>
              <Col>
                <Link to="/services/storage">
                  <StaticImage
                    src="../../../media/STORAGE2.png"
                    alt=""
                    height="175"
                  />
                </Link>
                <p>
                  Why choose Wessuc for storage? We are proud to announce we have just increased our storage capacity - which means we won't run out of space for your storage needs, whether we're your first stop, or your back up plan.
                </p>
              </Col>
            </Row>
          </Container>
        </main>
    </Layout>
  )
}

export default ResidualsPage